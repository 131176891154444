export const PublicContainerWidth = 1080;
export const UnauthenticatedSidePadding = 120;

// To migrate for newer containers, we will need to use new max-width.
// When all pages will be migrated, this will be the only max-width.
export const NewPublicContainerWidth = 1157;

export const EMAIL_REGEX =
  /^(?=.{1,254}$)(?=.{1,64}@.{1,255}$)[A-Za-z0-9!#$%'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%'*+/=?^_`{|}~-]+)*@(?:(?!-)[A-Za-z0-9-]{1,63}(?![A-Za-z0-9-])\.)+(?!-)[A-Za-z]{2,6}$/;
export const NUMBER_REGEX = /^[0-9]\d*$/;
export const NON_ZERO_NUMBER_REGEX = /^[1-9]\d*$/;
// matches empty string or prices
export const PRICE_REGEX = /^$|^\$?[0-9]*\.?[0-9]{0,2}$/;

/**
 * breakpoints
 * @deprecated in favor of theme breakpoints https://mui.com/material-ui/customization/breakpoints/
 */
export const SCREENS = {
  largeLaptop: 1440,
  laptop: 1024,
  tablet: 768,
};

export const DESKTOP_FIRST_SCREENS = {
  laptop: 1439,
  tablet: 1023,
  mobile: 767,
};

// Includes computed statuses and status texts
export const ALL_ORDER_STATUSES = {
  supplierReview: 'Supplier Review',
  accepted: 'Accepted',
  cancelled: 'Cancelled',
  declined: 'Declined',
  partiallyShipped: 'Partially Shipped',
  shipped: 'Shipped',
  nonShipped: 'Non Shipped',
  exception: 'Exception',
  delivered: 'Delivered',
  unknown: 'Unknown',
} as const;
export type ALL_ORDER_STATUSES_VALUES =
  typeof ALL_ORDER_STATUSES extends Record<string, infer I> ? I : never;

export const SUPPLIER_REVIEW_STATUS_LABEL = {
  title: 'The supplier is reviewing the Order',
  text: 'Supplier Review',
  color: '#7200CC',
  value: ALL_ORDER_STATUSES.supplierReview,
};

export const ACCEPTED_STATUS_LABEL = {
  title: 'The customer has accepted the Order',
  text: 'Accepted',
  color: '#0E00A3',
  value: ALL_ORDER_STATUSES.accepted,
};

export const PARTIALLY_SHIPPED_STATUS_LABEL = {
  title: 'The customer has partially shipped the Order',
  text: 'Partially Shipped',
  color: '#0E00A2',
  value: ALL_ORDER_STATUSES.partiallyShipped,
};

export const CANCELLED_STATUS_LABEL = {
  title: 'The customer has cancelled the Order',
  text: 'Cancelled',
  color: '#616161',
  value: ALL_ORDER_STATUSES.cancelled,
};

export const DECLINED_STATUS_LABEL = {
  title: 'The supplier denied the Order',
  text: 'Declined',
  color: '#EF5350',
  value: ALL_ORDER_STATUSES.declined,
};

export const SHIPPED_STATUS_LABEL = {
  title: 'The supplier shipped the Order',
  text: 'Shipped',
  color: '#1C8259',
  value: ALL_ORDER_STATUSES.shipped,
};

export const NON_SHIPPED_STATUS_LABEL = {
  text: 'Non-shipped',
  color: '#4C5BDB',
  value: ALL_ORDER_STATUSES.nonShipped,
};

export const INTEGRATION_EXCEPTION_STATUS_LABEL = {
  title: 'Integration exceptions occurred',
  text: 'Exceptions',
  color: '#ED6C02',
  value: ALL_ORDER_STATUSES.exception,
};

export const DELIVERED_STATUS_LABEL = {
  title: 'The order has been delivered',
  text: 'Delivered',
  color: '#156645',
  value: ALL_ORDER_STATUSES.delivered,
};

export const UNKNOWN_STATUS_LABEL = {
  title: 'Unknown order status',
  text: 'Unknown',
  color: '#757575',
  value: ALL_ORDER_STATUSES.unknown,
};
